@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
*{
  font-family: "Open Sans", sans-serif;
}

.d-flex{
  list-style-type: none;
  margin: 5px 10px;
}

.chat{
  margin: 3% 10%;
  background-color: rgb(246, 241, 245);
  border-radius: 10px;
  padding: 20px;
}

.input-field{
  display: block;
  padding: 10px;
  width: 100%;
  height: 20vh;
  resize: none;
  margin-bottom: 20px;
  border: 2px solid rgb(242, 234, 234);
  border-radius: 10px;
}

.succes{
  padding: 5px;
  background-color: white;
  border: 2px solid rgb(242, 234, 234);
  border-radius: 10px;

}

.margin-t{
  margin-top: 100px;
}

